<template>

  <v-container fluid>

    <div class="pa-0 px-0 ma-0">
      <div class="pa-0 px-0 ma-0">
        <v-btn class="mr-0" color="grey darken-2" outlined @click="setToday">
          {{ $t('generic.lang_today') }}
        </v-btn>
        <v-btn color="grey darken-2" fab icon text @click="$refs.calendar.prev()">
          <v-icon large>
            mdi-chevron-left
          </v-icon>
        </v-btn>

        <v-btn color="grey darken-2" fab icon small @click="$refs.calendar.next()">
          <v-icon large>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <strong v-if="$refs.calendar" class="font-weight-bolder">
          {{ $refs.calendar.title }}
        </strong>
        <v-spacer></v-spacer>
      </div>
    </div>

    <v-row class="pt-0 pb-7 mt-0">
      <v-col cols="12" lg="4" md="4" sm="12">
        <b-form-select v-model="res_type" :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
          :label-field="$t('generic.lang_constReservationType')" placeholder="type" size="sm">
          <b-form-select-option :value="-1">{{ $t('generic.lang_allTypes') }}</b-form-select-option>
          <b-form-select-option v-for="t in this.res_types" :key="t.uuid" :value="t.uuid">{{ t.name }}
          </b-form-select-option>
        </b-form-select>
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="12">
        <b-form-select v-model="status" :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
          :placeholder="$t('generic.lang_status')" se size="sm">
          <b-form-select-option :value="-1">{{ $t('generic.lang_allStatuses') }}</b-form-select-option>
          <b-form-select-option :value="1">{{ $t('generic.lang_confirmed') }}</b-form-select-option>
          <b-form-select-option :value="0">{{ $t('generic.lang_unconfirmed') }}</b-form-select-option>
        </b-form-select>
      </v-col>

      <v-col cols="12" lg="4" md="4" sm="12">
        <b-form-select v-model="source" :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
          :placeholder="$t('generic.lang_sources')" se size="sm">
          <b-form-select-option :value="-1">{{ $t('generic.lang_allSources') }}</b-form-select-option>
          <b-form-select-option value="phone">{{ $t('generic.lang_viaPhone') }}</b-form-select-option>
          <b-form-select-option value="online">{{ $t('generic.lang_online') }}</b-form-select-option>
        </b-form-select>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <b-form-select v-model="type" :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
          :placeholder="$t('generic.lang_source')" se size="sm">
          <b-form-select-option v-for="t in this.types" :key="t" :value="t">{{ t }}</b-form-select-option>
        </b-form-select>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <b-form-select v-model="weekday" :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
          :placeholder="$t('generic.lang_source')" se size="sm">
          <b-form-select-option v-for="(w, i) in this.weekdays" :key="i" :value="w.value">{{
            w.text
          }}
          </b-form-select-option>
        </b-form-select>
      </v-col>
    </v-row>


    <v-sheet class="pa-0 ma-0" height="600">
      <v-calendar ref="calendar" v-model="value" :event-color="getEventColor" :event-overlap-mode="mode"
        :event-overlap-threshold="30" :events="events" :type="type" :weekdays="weekday" @change="getEvents"
        @click:event="showEvent" @click:day="newReservation" @click:date="viewDay" @click:more="viewDay"
        @click:time="newReservation">
        <template v-slot:event="{ event }">
          <v-sheet height="100" :color="event.color" class="pl-1">
            <strong>{{ event.time }}</strong><span class="ml-2 ml-2 white--text">{{ event.name }}</span>
            <span v-if="Array.isArray(event.tables) && event.tables.length > 0"> ({{
              event.tables.map((elt) => elt.name).join(',')
            }})</span>
          </v-sheet>
        </template>
      </v-calendar>

      <v-menu v-if="this.selectedElement && this.selectedEvent" v-model="selectedOpen" :activator="selectedElement"
        :close-on-content-click="false" max-width="400" offset-x>
        <v-card color="white" flat min-width="350px" outlined>
          <v-toolbar class="elevation-0 text--grey" color="white" dark dense>
            <v-toolbar-title class="text-muted">
              {{ this.selectedEvent.resType_name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small @click="open_edit">
                  <font-awesome-icon :icon="['fal', 'edit']" class="text-muted" size="2x" style="font-size: 18px" />
                </v-btn>
              </template>
              {{ $t('generic.lang_edit') }}
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small @click="deleteItem(selectedEvent)">
                  <font-awesome-icon :icon="['fal', 'trash']" class="text-muted" size="2x" style="font-size: 18px" />
                </v-btn>
              </template>
              {{ $t('generic.lang_delete') }}
            </v-tooltip>

            <v-tooltip v-if="parseInt(this.selectedEvent.canceled) != 0" bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small @click="cancel(selectedEvent)">
                  <v-icon class="text-muted">cancel</v-icon>
                </v-btn>
              </template>
              {{ $t('generic.lang_cancel') }}
            </v-tooltip>

            <v-tooltip v-if="this.selectedEvent.confirmed === 0" bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small @click="confirmItem(selectedEvent)">
                  <font-awesome-icon :icon="['fal', 'check']" class="text-muted" size="2x" style="font-size: 21px" />
                </v-btn>
              </template>
              {{ $t('generic.lang_confirm') }}
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small @click="open_email(selectedEvent)">
                  <font-awesome-icon :icon="['fal', 'envelope']" class="text-muted" size="2x" style="font-size: 21px" />
                </v-btn>
              </template>
              {{ $t('generic.lang_contactCustomer') }}
            </v-tooltip>


            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon small @click="close()">
                  <font-awesome-icon :icon="['fal', 'window-close']" class="text-muted" size="2x"
                    style="font-size: 21px" />
                </v-btn>
              </template>
              {{ $t('generic.lang_close') }}
            </v-tooltip>

          </v-toolbar>
          <v-card-text>
            <div class="pb-4">
              <v-icon :color="this.selectedEvent.color" class="pb-1" large>
                stop
              </v-icon>
              <span>

                <strong class="font-weight-bolder font-size-lg">{{ this.selectedEvent.name }}</strong>
                <br />
                <span class="ml-8"><strong>{{ $t('generic.lang_source') }}:</strong> {{ this.selectedEvent.source
                  }}</span>
                <br />
                <span class="ml-8"><strong>{{ $t('generic.lang_from') }}:</strong> {{
                  this.selectedEvent.start.toLocaleString() }}</span>
                <br />
                <span class="ml-8"><strong>{{ $t('generic.lang_to') }}:</strong> {{
                  this.selectedEvent.end.toLocaleString()
                  }}</span>

              </span>
            </div>

            <div class="pb-3">
              <font-awesome-icon :icon="['fal', 'hashtag']" class="text-muted" size="2x" style="font-size: 15px" />
              <span class="text--black ml-5 font-weight-bold text-primary">
                {{ this.selectedEvent.code }}
              </span>
            </div>


            <div class="pb-3">
              <font-awesome-icon :icon="['fal', 'users']" class="text-muted" size="2x" style="font-size: 15px" />
              <span class="text--black ml-5">
                {{ this.selectedEvent.person }}
              </span>
            </div>


            <div class="pb-3">
              <font-awesome-icon :icon="['fal', 'keynote']" class="text-muted" size="2x" style="font-size: 15px" />
              <span class="text--black ml-5">
                {{ this.selectedEvent.tables.map((elt) => elt.name).join(',') }}
              </span>
            </div>

            <div class="pb-3">
              <font-awesome-icon :icon="['fal', 'phone']" class="text-muted" size="2x" style="font-size: 15px" />
              <span class="text--black ml-5">
                {{ this.selectedEvent.phone }}
              </span>
            </div>

            <div class="pb-3">
              <font-awesome-icon :icon="['fal', 'envelope']" class="text-muted" size="2x" style="font-size: 15px" />
              <span class="text--black ml-5">
                {{ this.selectedEvent.email }}
              </span>
            </div>

            <div>
              <font-awesome-icon :icon="['fal', 'sticky-note']" class="text-muted" size="2x" style="font-size: 15px" />
              <span class="text-black ml-5">
                {{ this.selectedEvent.note }}
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-sheet>

    <v-sheet>
      <v-dialog v-model="show_create" max-width="700" scrollable>
        <create-reservation v-if="this.show_create" :show_close="true" v-bind:default_date="this.create_date"
          v-bind:default_time="this.create_time" @close_create="close_create" @close="show_create = false"
          @getEvents="getEvents" />
      </v-dialog>
    </v-sheet>

    <!-- loader dialog -->

    <v-dialog v-model="events_loader" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ this.$t('datatables.lang_Processing') }}
          <v-progress-linear class="mb-0" color="white" indeterminate></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- email dialog -->

    <v-dialog v-model="email_dialog" max-width="600px" persistent>
      <v-form ref="emailForm" lazy-validation>
        <div class="card">
          <div class="card-header">
            {{ $t('generic.lang_email') }}
          </div>

          <div class="card-body">
            <v-row>
              <v-col class="pt-0 mt-0 pb-0 mb-0" cols="12">
                <v-text-field v-model="form.subject" :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[v => !!v]"
                  clearable dense :label="$t('settings.lang_mail_subject')" outlined @focus="showTouchKeyboard" />
              </v-col>


              <v-col class="pt-0 mt-0" cols="12">
                <quill-editor v-model="form.message" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                  :rules="[v => !!v]" @focus="showTouchKeyboard"></quill-editor>
              </v-col>
              <v-col cols="12">
                <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                  <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                    :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
                    :layout="touchKeyboard.layout" :options="touchKeyboard.options" class="internalWidth" />
                </div>
              </v-col>

            </v-row>
          </div>

          <div class="card-footer text-right pa-1 pr-3">
            <v-spacer />
            <v-btn class="ma-1" color="error" @click="email_dialog = false">
              {{ $t('generic.lang_cancel') }}
            </v-btn>
            <v-btn :disabled="email_loader" :loading="email_loader" class="ma-1" color="primary" @click="send">
              {{ $t('support.lang_supportSend') }}
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-dialog>


    <edit-reservation v-if="this.edit_dialog" v-model="edit_dialog" :default_data="this.selectedEvent"
      v-bind:dialog="this.edit_dialog" @close_edit="close_edit()" @getEvents="getEvents()" />

  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import CreateReservation from "@/components/reservation/reservations/CreateReservation";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor';
import mixin from "@/mixins/KeyboardMixIns";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faCheck,
  faEdit,
  faEnvelope,
  faHashtag,
  faPhone,
  faStickyNote,
  faTrash,
  faWindowClose
} from '@fortawesome/pro-light-svg-icons';
import EditReservation from "@/components/reservation/reservations/EditReservation";
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faWindowClose, faEnvelope, faTrash, faEdit, faPhone, faStickyNote, faCheck, faHashtag);

export default {
  name: "ReservationCalendar",
  components: {
    CreateReservation, quillEditor,
    EditReservation,
    'font-awesome-icon': FontAwesomeIcon
  },
  mixins: [mixin],
  data: () => ({
    search: "",
    edit_dialog: false,
    quillOptions: {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'align': [] }],
          [{ 'font': [] }],
        ],
      },
    },
    sendLoader: false,
    form: {
      email: "",
      subject: "",
      message: ""
    },
    email_dialog: false,
    email_loader: false,
    events_loader: false,
    selectedEvent: null,
    create_date: null,
    create_time: null,
    show_create: false,
    selectedOpen: false,
    selectedElement: null,
    reservations: [],
    loadData: false,
    type: 'month',
    types: ['month', 'week', 'day', '4day'],
    mode: 'stack',
    modes: ['stack', 'column'],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
      { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
      { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
      { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
    ],
    value: '',
    events: [],
    res_types: [],
    res_type: -1,
    source: -1,
    status: -1
  }),
  mounted() {
    this.setToday();
    this.getEvents();
    this.loadTypes();
  },
  watch: {
    status(val) {
      this.getEvents();
    },
    source(val) {
      this.getEvents();
    },
    res_type(val) {
      this.getEvents();
    },
  },
  methods: {
    close_edit() {
      this.edit_dialog = false;
      this.selectedEvent = null;
    },
    setToday() {
      this.value = '';
    },
    open_edit() {
      this.edit_dialog = true;
    },
    send() {
      if (!this.$refs.emailForm.validate()) return;
      this.email_loader = true;
      this.axios.post(ENDPOINTS.EMAILS.RESERVATIONS.CONTACTS, this.form)
        .then((res) => {
          if (res.data.STATUS === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_success'),
              color: "success"
            });

            this.email_dialog = false;
            this.$refs.emailForm.reset();
            this.form.message = "";
          } else if (res.data.STATUS === "FAILED") {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error"
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error"
          });
        }).finally(() => {
          this.email_loader = false;
        })
    },
    open_email(item) {
      this.form.email = item.email;
      this.close();
      this.email_dialog = true;
    },
    close_create() {
      this.create_time = "";
      this.create_date = "";
      this.show_create = false;
    },
    close() {
      this.selectedOpen = false;
      this.selectedEvent = null;
      this.selectedElement = null;
    },
    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    deleteItem(item) {
      this.$swal({
        title: this.$t('reservation.lang_deleteReservation'),
        text: this.$t('reservation.lang_deleteReservationBody'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.loading = true;
          this.loadData = true;
          this.events_loader = true;

          this.axios.post(ENDPOINTS.RESERVATION.RESERVATIONS.DELETE, item).then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_success'),
                color: "success"
              });
              this.close();
              this.getEvents();
            } else if (res.data.STATUS === "FAILED") {
              Events.$emit("showSnackbar", {
                message: res.data.msg,
                color: "error"
              });
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch((err) => {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error"
            });
          }).finally(() => {
            this.loading = false;
            this.loadData = false;
            this.events_loader = false;
          })

        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    newReservation({ date, time }) {
      this.create_date = date;
      this.create_time = time;


      this.show_create = true;
    },
    loadTypes() {
      this.loadData = true;
      this.res_types = [];
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.TYPES.GET).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.res_types = res.data.types;

        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {

      }).finally(() => {
        this.loadData = false;
      })
    },
    getEvents() {
      const events = [];

      this.loadData = true;
      this.events_loader = true;
      this.reservations = [];
      this.axios.post(ENDPOINTS.RESERVATION.RESERVATIONS.GET
        + '?type=' + this.res_type + '&source=' + this.source + '&status=' + this.status
      ).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.reservations = res.data.reservations;


          this.reservations.forEach((res) => {
            let first = new Date(res.date + 'T' + res.time);
            let second = new Date(first.getTime() + parseInt(res.max_time) * 1000);

            let elt = Object.assign({}, res);
            elt.start = first;
            elt.end = second;
            elt.color = res.confirmed == true ? 'success' : 'warning';
            elt.timed = true;
            elt.tables = res.tables;
            events.push(elt);
          });
          this.events_loader = false;

          this.$emit('reloadStatistics');
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadData = false;
        this.events_loader = false;
      })


      this.events = events
    },
    getEventColor(event) {
      return event.color
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    confirmItem(item) {
      this.confirmReservation(item)
    },
    confirmReservation(item) {
      this.loading = true;
      this.loadData = true;
      this.events_loader = true;

      this.axios
        .post(ENDPOINTS.RESERVATION.RESERVATIONS.CONFIRM, {
          uuid: item.uuid,
          confirmed: true
        })
        .then((res) => {
          if (res.data.STATUS === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_success"),
              color: "success",
            });
            this.close();
            this.getEvents();
          } else if (res.data.STATUS === "FAILED") {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
          this.loadData = false;
          this.events_loader = false;
        });
    },
    cancel(item) {
      item.canceled = (item.canceled === null) ? 0 : !parseInt(item.canceled);
      this.saveReservation(item)
      this.getEvents();
      this.close();
    },
    saveReservation(item) {
      this.events_loader = true;

      this.axios.post(ENDPOINTS.RESERVATION.RESERVATIONS.UPDATE, item).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.events_loader = false;
      })
    }
  },
}
</script>

<style scoped></style>
